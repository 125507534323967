<template>
  <div>
    <h1>AI财报分析</h1>

    <div class="upload-group">
        <label for="file-upload">财报文件上传(pdf):</label>
        <input type="file" id="file-upload" @change="handleFileUpload">
        <span v-if="fileWarning" class="warning"> {{ fileWarning }} </span>
    </div>
    <div class="dropdown-group">
        <!-- Dropdown 1 -->
        <label for="industry">行业:</label>
        <select v-model="industry" id="industry" :disabled="!isFileUploaded">
        <option v-for="(option, index) in industryOptions" :key="index" :value="option">
            {{ option }}
        </option>
        </select>
    </div>

    <div class="dropdown-group">
    <!-- Dropdown 2 -->
    <label for="jobTitle">职位:</label>
    <select v-model="jobTitle" id="jobTitle" :disabled="!isFileUploaded">
      <option v-for="(option, index) in jobTitleOptions" :key="index" :value="option">
        {{ option }}
      </option>
    </select>
    </div>

    <div class="submit-group">
        <button @click="handleSubmit" :disabled="!isSubmitting">Submit</button>
    </div>
    <div v-if="isRequest" class="loading-message">
        Submitting your data, please wait...
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { marked } from "marked";  // 注意这里的导入方式修改
import html2pdf from "html2pdf.js";
export default {
  data() {
    return {
      industryOptions: [], // 存储第一个下拉菜单的行业数据
      jobTitleOptions: [], // 存储第二个下拉菜单的行业数据
      industry: null, // 第一个下拉菜单选择的值
      jobTitle: null,  // 第二个下拉菜单选择的值
      selectedFile: null,
      fileWarning: '',
      isSubmitting: false,
      isFileUploaded: false,
      isRequest: false
    };
  },
  methods: {
    // 获取第一个下拉菜单的数据
    async fetchIndustryOptions() {
      try {
        const response = await axios.get('/openai/v1/analyze/industries');
        this.industryOptions = response.data; // 数据是简单的字符串数组
        if(this.industryOptions.length >0){
            this.industry = this.industryOptions[0];
        }
      } catch (error) {
        console.error('Error fetching data for industry:', error);
      }
    },
    // 获取第二个下拉菜单的数据
    async fetchJobTitleOptions() {
      try {
        const response = await axios.get('/openai/v1/analyze/jobTitles');
        this.jobTitleOptions = response.data; // 数据是简单的字符串数组
        if(this.jobTitleOptions.length >0){
            this.jobTitle = this.jobTitleOptions[0];
        }
      } catch (error) {
        console.error('Error fetching data for jobTitle:', error);
      }
    },
    handleFileUpload(event){
        const file = event.target.files[0];
        if(file){
            if(file.size > 10 * 1024 * 1024){
                this.fileWarning = "File size exceeds 10MB, Please select a smller";
                this.isFileUploaded = false;
                this.isSubmitting = false;
            }else{
                this.selectedFile = event.target.files[0];
                this.isFileUploaded = true;
                this.fileWarning = '';
                this.isSubmitting = true;
            }
        }else{
            this.fileWarning = 'Please upload a file';
            this.isFileUploaded = false;
            this.isSubmitting = false;
        }
    },
    addPageBreaks(container, pageHeight) {
        console.log("input container and pageHeight", container, pageHeight);
        const children = container.children;  // 获取容器的所有子元素
        let totalHeight = 0;  // 当前已处理内容的总高度

        for (let i = 0; i < children.length; i++) {
            const child = children[i];
            totalHeight += child.offsetHeight;  // 累加每个元素的高度

            // 如果累加高度超出了页面高度，插入分页符
            if (totalHeight > pageHeight) {
                const pageBreak = document.createElement('div');
                pageBreak.style.pageBreakBefore = 'always';  // 使用 CSS 分页符
                container.insertBefore(pageBreak, child);  // 在当前元素之前插入分页符
                totalHeight = child.offsetHeight;  // 重置总高度，重新计算新页面内容
            }
        }
        console.log("output container and pageHeight", container, pageHeight);
    },
    async handleSubmit(){
        if(!this.selectedFile){
            console.log("please select");
            this.fileWarning = 'Please select a file befor submitting.';
            return;
        }
        console.log('Selected Industry 1:', this.industry);
        console.log('Selected Industry 2:', this.jobTitle);
        console.log('Selected File:', this.selectedFile);

        const formData = new FormData();
        formData.append('industry',this.industry);
        formData.append('jobTitle',this.jobTitle);
        formData.append('file',this.selectedFile);

        try{
            this.isRequest = true;
            const response = await axios.post('/openai/v1/analyze/assistant', formData, {
                headers: {
                    'Content-Type':'multipart/form-data',
                },
            });
            console.log("response ", response);
            // 从响应中提取 markdown 数据
            const markdown = response.data.markdown;
            console.log("response data is ", markdown);

            // 将 markdown 转换为 HTML
            const renderedHtml = marked(markdown);

            // 创建一个隐藏的 div 用于渲染 HTML 并生成 PDF
            const pdfContainer = document.createElement("div");
            pdfContainer.innerHTML = renderedHtml;
            /*
             pdfContainer.style.display = 'black';  // 确保渲染时可见
             *pdfContainer.style.margin = "0";
             *pdfContainer.style.padding = "0";
             *pdfContainer.style.width = "210mm";  // A4 尺寸的宽度
             *pdfContainer.style.height = "297mm"; // A4 尺寸的高度
             *pdfContainer.style.overflow = "hidden"; // 隐藏溢出的内容
             *pdfContainer.style.pageBreakInside = "auto"; // 确保内容可以分页
             */
            document.body.appendChild(pdfContainer); // 将 div 添加到文档中

            //const A4_HEIGHT_PX = 297 * 3.78;  // A4 高度 (像素)
            //this.addPageBreaks(pdfContainer, A4_HEIGHT_PX);  // 处理分页

            console.log("pdf container is ", pdfContainer);
            this.isSubmitting = true;
            this.isRequest = false;

            html2pdf()
            .from(pdfContainer)
            .set({
                filename: 'analyze-report.pdf',
                //margin: [10, 10, 10, 10], // 上、右、下、左边距
                html2canvas: {width:858,  scale: 2 , useCORS: true}, // 渲染比例
                jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
                pagebreak: {mode:'avoid-all'}
            })
            .save("analyze_report.pdf")
            .then(() => {
                // PDF 保存后，移除隐藏的 div
                //document.body.removeChild(pdfContainer);
            })
            .catch((error) => {
                console.error("PDF 生成失败", error);
            });
            /*
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'analyze-report.pdf');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            */
        }catch(error){
            this.responseMessage = `Error: ${error.response ? error.response.data : 'Unknow error'}`;
            this.isRequest = false;
        }finally{
            this.isSubmitting = false;
            this.isRequest = false;
        }
    }
  },
  mounted() {
    // 在组件加载时，获取两个下拉菜单的数据
    this.fetchIndustryOptions();
    this.fetchJobTitleOptions();
  }
};
</script>

<style scoped>
.dropwown-group{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.upload-group,.submit-group{
    margin-bottom:20px;
}
label {
  margin-right: 10px;
}

select,input[type="file"],button{
  padding: 5px;
  width: 200px;
  box-sizing: border-box;
}
.warning{
    color: red;
    margin-left:10px;
}
div {
  padding: 10px;
  background-color: #f9f9f9;
}
.loading-message {
  color: blue;
  margin-top: 10px;
}
</style>
